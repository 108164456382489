
					@import './src/sass/variables';
					@import './src/sass/mixins';
				
























































































































































$options_line_padding: 4px 25px 6px 10px;

.vm--modal > .bool-input::v-deep {
	margin-bottom: $default_padding * 1.5;
}

.block-inner::v-deep {
	padding: 0 !important;

	.format-bar {
		left: $site__padding * 2;
	}

	.ProseMirror {
		min-height: $site__padding * 4;
		padding: $site__padding $site__padding * 2;
		font-size: 1em;
		line-height: $line__height;
		background-color: transparent;
		transition: background-color 0.2s ease-out;
		border-radius: 0;
	}

	.ProseMirror-focused {
		background-color: $color__white;
		box-shadow: none;
	}

	.options {
		position: relative;
		cursor: pointer;
		background-color: $color__white;
		padding: $options_line_padding;
		margin: 0 3px;
		border-top-left-radius: $border_radius;
		border-top-right-radius: $border_radius;
		box-shadow: $box_shadow__input;
		min-width: 150px;
		display: inline-block;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 8px;
			border: 5px solid transparent;
			border-top-color: $color__dark;
			transform: translateY(-15%);
		}
	}
}

ol {
	list-style-type: none;
	margin-left: 0;
	padding-left: 0;

	li {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 5px;
		border-radius: $border_radius;

		&:nth-child(odd) {
			background-color: $color__gray;
		}

		.bool-input {
			flex: 1;
			margin: 0;
		}

		& > svg {
			cursor: pointer;
			padding: 9px;
			box-sizing: content-box;
			margin: -5px;
		}
	}

	li,
	.bool-input::v-deep {
		cursor: grab;

		.input {
			cursor: pointer;
		}

		&:active {
			cursor: grabbing;
		}
	}
}

.ProseMirror-focused {
	.options {
		background-color: $color__gray;
	}
}
